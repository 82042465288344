import ApiService from "@/common/api.service";

export default {
  getUserExam(userExamId) {
    return ApiService.post(`/user-exams/${userExamId}/start`);
  },
  updatePracticeAnswers(userExamId ,data){
    return ApiService.post(`/user-exams/${userExamId}/answer`, data);
  }
};
