<template>
  <div>
    <div class="page-wrapper" id="sandbox">
      <div class="sidebar-wrapper p-4" data-simplebar="init">
        <p class="articleTitle">
          {{ Directions[testType].Title }}
        </p>
        <div class="direction" v-html="Directions[testType].Direction"></div>
      </div>
      <div class="page-content-wrapper" style="height: auto !important;"></div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import $ from "jquery";
import TextHighlighter from "@/views/act/assets/js/TextHighlighter.js";

export default {
  metaInfo() {},

  components: {},

  mixins: [],

  props: {
    testType: {
      type: String,
      default: "English",
      validator: value =>
        ["English", "Math", "Reading", "Science"].includes(value)
    }
  },
  data() {
    return {
      Directions: {
        English: {
          Title: "Begin English Test—45 minutes, 75 questions",
          Direction: `In the five passages that follow, certain words and phrases are underlined and highlighted. On the right-hand side of the screen, you will find alternatives for the underlined and highlighted part. In most cases, you are to choose the one that best expresses the idea, makes the statement appropriate for standard written English, or is worded most consistently with the style and tone of the passage as a whole. If you think the original version is best, choose "NO CHANGE." In some cases, you will find on the right-hand side of the screen a question about the underlined and highlighted part. You are to choose the best answer to the question.<br />
              You will also find questions about a section of the passage, or about the passage as a whole. These questions do not refer to an underlined portion of the passage, but rather refer to a letter or letters, a number or numbers, or an asterisk within square brackets [ ].<br />
              For each question, choose the alternative you consider best, select the circle next to your answer, then select the Next button. Read each passage through once, using the scroll bar to see the entire passage, before you begin to answer the questions that accompany the passage. For many of the questions, you must read several sentences beyond the question to determine the answer. Be sure that you have read far enough ahead each time you choose an alternative.<br />
              Select the <b>Next</b> button to proceed.`
        },
        Math: {
          Title: "Begin Mathematics Test—60 minutes, 60 questions",
          Direction: `Solve each problem, choose the correct answer, and then select your answer. Select the Next button.<br />
            Do not linger over problems that take too much time. Solve as many as you can; then return to the others in the time you have left for this test.<br />
            You may use your calculator for any problems you choose, but some of the problems may best be done without using a calculator.<br />
            Note: Unless otherwise stated, all of the following should be assumed.<br />
            Illustrative figures are NOT necessarily drawn to scale.<br />
            Geometric figures lie in a plane.<br />
            The word line indicates a straight line.<br />
            The word average indicates arithmetic mean.<br />
            Select the <b>Next</b> button to proceed.
            `
        },
        Reading: {
          Title: "Begin Reading Test—35 minutes, 40 questions",
          Direction: `There are several passages in this test. Each passage is accompanied by several questions. Some passages are grouped within a single scrollable window and the corresponding questions will refer to Passage A, Passage B, or both passages.<br />
          Read each passage through once, using the scroll bar to see the entire passage, before you begin to answer the questions that accompany the passage. After reading a passage, choose the best answer to each question, select the circle next to it, then select the Next button. You may refer to the passages as often as necessary.<br />
          Select the <b>Next</b> button to proceed.`
        },
        Science: {
          Title: "Begin Science Test—35 minutes, 40 questions",
          Direction: `There are several passages in this section. Each passage is followed by several questions. Read each passage through once, using the scroll bar to see the entire passage, before you begin to answer the questions that accompany it. After reading a passage, choose the best answer to each question, select the circle next to it, then select the Next button. You may refer to the passages as often as necessary.<br />
          You are NOT permitted to use a calculator on this section.<br />
          Select the <b>Next</b> button to proceed.`
        }
      }
    };
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
.direction {
  line-height: 1.8rem;
  font-size: 0.9rem;
  font-weight: normal;
}
</style>
<style scoped src="@/views/act/assets/css/act.css"></style>

<style scoped src="@/views/act/assets/css/simplebar.css"></style>
